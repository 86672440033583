import axios from 'axios';
import { ENV, TG_CHAT_ID, TG_POST_URL } from '../constants';

export const isRunningInDev = () => ENV === 'development';

export const getBrowserSpecs = () => {
  let ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null)
      return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] };
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return { name: M[0], version: M[1] };
};

export const sendTelegramMessage = (message, notify) => {
  const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
  const params = new URLSearchParams();
  params.append('chat_id', TG_CHAT_ID);
  params.append('text', message);
  params.append('disable_notification', !notify.toString());
  axios.post(TG_POST_URL, params, { headers }).catch(() => {});
};

export const reportVisit = () => {
  const browserSpecs = getBrowserSpecs();
  const message = `Rendered by ${browserSpecs.name} ${browserSpecs.version}.`;
  if (isRunningInDev()) {
    return console.log('[DEV] Visit report skipped', message);
  }
  sendTelegramMessage(message, false);
};
