export const EducationPanel = ({ identifyWith, grade, paragraph }) => {
  return (
    <div className="panel panel-default">
      <div className="panel-heading" role="tab" id={'heading' + identifyWith}>
        <h4 className="panel-title">
          <a
            className="collapsed"
            data-toggle="collapse"
            data-parent="#accordion"
            href={'#collapse' + identifyWith}
            aria-expanded="false"
            aria-controls={'collapse' + identifyWith}>
            {grade}
          </a>
        </h4>
      </div>
      <div
        id={'collapse' + identifyWith}
        className="panel-collapse collapse"
        role="tabpanel"
        aria-labelledby={'heading' + identifyWith}
        style={{ whiteSpace: 'pre-wrap' }}>
        <div className="panel-body">
          <p>{paragraph}</p>
        </div>
      </div>
    </div>
  );
};
