import { Component } from 'react';
import { SidebarIcon } from './sidebarIcon';

export default class Sidebar extends Component {
  render() {
    return (
      <div>
        <div>
          <nav
            href="#navbar"
            className="js-colorlib-nav-toggle colorlib-nav-toggle"
            data-toggle="collapse"
            data-target="#navbar"
            aria-controls="navbar">
            <i />
          </nav>
          <aside id="colorlib-aside" className="border js-fullheight">
            <div className="text-center">
              <div className="author-img" style={{ backgroundImage: 'url(images/about.jpg)' }} />
              <h1 id="colorlib-logo">
                <a href="/">Javier Sánchez</a>
              </h1>
            </div>
            <nav id="colorlib-main-menu" role="navigation" className="navbar">
              <div id="navbar" className="collapse">
                <ul>
                  <li className="active">
                    <a href="#home" data-nav-section="home">
                      Introduction
                    </a>
                  </li>
                  <li>
                    <a href="#about" data-nav-section="about">
                      About
                    </a>
                  </li>
                  <li>
                    <a href="#timeline" data-nav-section="timeline">
                      Timeline
                    </a>
                  </li>
                  <li>
                    <a href="#education" data-nav-section="education">
                      Education
                    </a>
                  </li>
                  <li>
                    <a href="#blog" data-nav-section="blog">
                      Blog
                    </a>
                  </li>
                  <li>
                    <a href="#contact" data-nav-section="contact">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <nav id="colorlib-main-menu">
              <ul>
                <SidebarIcon icon="twitter2" link="https://twitter.com/xtrs84zk" />
                <SidebarIcon icon="instagram" link="https://www.instagram.com/xtrs84zk/" />
                <SidebarIcon icon="linkedin2" link="https://www.linkedin.com/in/xtrs84zk/" />
                <SidebarIcon icon="github" link="https://github.com/xtrs84zk" />
                <SidebarIcon icon="blogger2" link="https://forum.xda-developers.com/member.php?u=5472827" />
                <SidebarIcon icon="reddit" link="https://www.reddit.com/user/xtrs84zk/" />
              </ul>
            </nav>
          </aside>
        </div>
      </div>
    );
  }
}
