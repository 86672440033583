import React, { Component } from 'react';
import './App.css';
import { reportVisit } from './utils';
import Sidebar from './components/sidebar';
import Introduction from './components/introduction';
import About from './components/about';
import Timeline from './components/timeline';
import Blog from './components/blog';
import Contact from './components/contact';
import Education from './components/education';

class App extends Component {
  componentDidMount() {
    reportVisit();
  }
  render() {
    return (
      <div id="colorlib-page">
        <div id="container-wrap">
          <Sidebar />
          <div
            id="colorlib-main"
            className="app-container col-xs-12 d-flex justify-content-center">
            <Introduction />
            <About />
            <Timeline />
            <Education />
            <Blog />
            <Contact />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
