export const AboutBox = ({ title, description, icon, color }) => {
  return (
    <div className="col-md-4 text-center animate-box ">
      <div className={'services color-' + color}>
        <span className="icon">
          <i className={'icon-' + icon} />
        </span>
        <div className="desc">
          <h3>{title} </h3>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};
