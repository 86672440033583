export const Article = ({ title, text, timeSpan, color }) => {
  return (
    <div>
      <article
        className="timeline-entry animate-box"
        data-animate-effect="fadeInTop">
        <div className="timeline-entry-inner">
          <div className={`timeline-icon color-${color}`}>
            <i className="icon-pen2" />
          </div>
          <div className="timeline-label">
            <h2>
              {title}
              <span>{` ${timeSpan}`}</span>
            </h2>
            <p>{text}</p>
          </div>
        </div>
      </article>
    </div>
  );
};
