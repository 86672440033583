import { Component } from 'react';
import { Article } from './article';
import { SectionHeader } from './sectionHeader';

export default class Timeline extends Component {
  render() {
    return (
      <section
        className="colorlib-experience row col-md-12"
        data-section="timeline">
        <div className="colorlib-narrow-content">
          <SectionHeader title="highlights" subTitle="Timeline" />
          <div className="row col-md-12 timeline-centered">
            <Article
              title="Software Engineer at ProcessWay"
              text="Part of an amazing team building the software of tomorrow, today."
              timeSpan="– Feb 2021"
              color="2"
            />
            <Article
              title="Internship at ProcessWay"
              text="Developing my skills with real world applications."
              timeSpan="– Nov 2020"
              color="6"
            />
          </div>
        </div>
      </section>
    );
  }
}
