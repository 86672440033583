import React, { Component } from 'react';
import { SectionHeader } from './sectionHeader';
import { EducationPanel } from './educationPanel';

export default class Education extends Component {
    render() {
        return (
            <section className="colorlib-education" data-section="education">
                <div className="colorlib-narrow-content">
                    <div className="row">
                        <SectionHeader
                            title="Education"
                            subTitle="Where we've come" />
                    </div>
                    <div className="row">
                        <div className="col-md-12 animate-box" data-animate-effect="fadeInLeft">
                            <div className="fancy-collapse-panel">
                                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                    <EducationPanel
                                        identifyWith="One"
                                        grade="Bachelor's degree"
                                        paragraph={`Instituto Tecnológico de Saltillo | 2021\nGot my degree in Computer Systems Engineering with specialization on the Internet of Things.\nDuring this time, I took courses like Big Data, Cloud Computing, Computer Networks, and OOP. I did also form part of the Student Chapter during most of my time there.`}
                                    />
                                    <EducationPanel
                                        identifyWith="Two"
                                        grade="High School Secondary Education"
                                        paragraph={"Ateneo Fuente | 2015 \n Completed my higher education with major subjects as Physics, Chemistry & Maths. \n During my time at school, I developed speech and social skills. \n The interest in solving problems was a part of me already, but at this time it really started to get traction."}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}