import { Component } from 'react';
import { ListItem } from './listItem';

export default class Introduction extends Component {
  render() {
    let blogLink = window.location.protocol + '//blog.' + window.location.host;
    return (
      <div>
        <section
          id="colorlib-hero"
          className="js-fullheight"
          data-section="home">
          <div className="flexslider js-fullheight">
            <ul className="slides">
              <ListItem
                title="Hi."
                subtitle="I'm Javier"
                link="https://github.com/xtrs84zk/xtrs84zk/raw/master/JavierSánchez.pdf"
                buttonTitle="View resume"
                image="url(images/img_bg.jpg)"
                icon="download4"
                style={{
                  color: 'rgb(255,255,255)',
                  background: 'rgba(96, 96, 96, 0.6)',
                  border: '1px solid #fff',
                }}
              />
              <ListItem
                title="I love coding"
                subtitle="things !!"
                link="https://github.com/xtrs84zk"
                buttonTitle="View Projects"
                image="url(images/1.jpeg)"
                icon="briefcase3"
                style={{
                  color: 'rgb(255,255,255)',
                  background: 'rgba(128, 128, 128, 0.4)',
                  border: '1px solid #fff',
                }}
              />
              <ListItem
                title="I often"
                subtitle="write ..."
                link={blogLink}
                buttonTitle="View Blog"
                image="url(images/EWRbCruUMAAfkME.jpeg)"
                icon="book"
                style={{
                  color: 'rgb(255,255,255)',
                  background: 'rgba(128, 128, 128, 0.4)',
                  border: '1px solid #fff',
                }}
              />
            </ul>
          </div>
        </section>
      </div>
    );
  }
}
