import React from 'react';
import BlogEntry from './blogEntry';
import { SectionHeader } from './sectionHeader';

export default class Blog extends React.Component {
  render() {
    let blogLink = `${window.location.protocol}//blog.${window.location.host}/`;
    return (
      <section className="colorlib-blog row col-md-12" data-section="blog">
        <div className="colorlib-narrow-content">
          <div className="row">
            <SectionHeader
              style={{ paddingLeft: '70px' }}
              title="Know more."
              subTitle="Editor's choice"
            />
          </div>
          <div className="row">
            <BlogEntry
              date="February 13, 2018"
              minutesToRead="12"
              title="Elegía"
              description="Un fantasma del ayer que no respira más. Hoy se puede retirar y nos deleita, en su andar, con una gran prosa."
              picture="https://pbs.twimg.com/media/EYhqw63UEAA-TPT?format=jpg&name=small"
              pictureDescription="."
              entryLink={`${blogLink}elegia/`}
            />
            <BlogEntry
              date="January 13, 2020"
              minutesToRead="7"
              title="Litigio"
              description="La historia de un hombre que ha descubierto un nuevo amor; se toca el corazón y desciende a la locura."
              picture="https://pbs.twimg.com/media/EbGLP3nVAAI0tVI?format=jpg&name=small"
              pictureDescription="."
              entryLink={`${blogLink}litigio/`}
            />
            <BlogEntry
              date="September 28, 2019"
              minutesToRead="10"
              title="Untitled 26."
              description="Una nueva ruta de intrusión. ¿Qué resta cuando un verbo pierde su sentido? ¿Qué hay más allá de tu efímero existir?"
              picture="https://blog.javiersanchez.dev/assets/ego_dilexi_mori_tantum.jpeg"
              pictureDescription="."
              entryLink={`${blogLink}untitled26/`}
            />
          </div>
        </div>
      </section>
    );
  }
}
