export const SectionHeader = ({ title, subTitle }) => {
  return (
    <div className="row">
      <div
        className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box text-xs-center text-md-left"
        data-animate-effect="fadeInLeft">
        <span className="heading-meta"> {title} </span>
        <h2 className="colorlib-heading"> {subTitle} </h2>
      </div>
    </div>
  );
};
