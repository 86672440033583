import { Component } from 'react';
import { AboutBox } from './aboutBox';
import { SectionHeader } from './sectionHeader';

export default class About extends Component {
  render() {
    return (
      <section className="colorlib-about  row col-md-12" data-section="about">
        <div className="colorlib-narrow-content">
          <SectionHeader title="About" subTitle="Who am I?" />
          <p className="text-xs-center">
            "Un fósforo cuando se apaga el sol."
          </p>
          <p className="text-xs-center">
            Software Engineer. Web Developer. Writer.
          </p>
        </div>
        <div className="colorlib-narrow-content">
          <SectionHeader
            title="What do I do?"
            subTitle="Here is some of my expertise"
          />
          <div className="row row-pt-md" style={{ paddingLeft: '15px' }}>
            <AboutBox
              title="Web Development"
              description="From Thymeleaf to ReactJS. Vanilla HTML? Sure, why not. Design, code, deployment. I love it!"
              icon="bulb"
              color="1"
            />
            <AboutBox
              title="Programming"
              description="JavaScript is my defacto languaje. However, I'm fluent in Java, Prolog, R. I love bash and zsh."
              icon="phone3"
              color="3"
            />
            <AboutBox
              title="Database Management"
              description="Maybe my favorite part of a well designed project."
              icon="data"
              color="5"
            />
          </div>
        </div>
      </section>
    );
  }
}
