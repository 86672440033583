import React, { useState } from 'react';
import { SectionHeader } from './sectionHeader';
import { sendTelegramMessage } from '../utils';

const Contact = () => {
  const [contact, setContact] = useState('');
  const [message, setMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  const clearForm = () => {
    setContact('');
    setMessage('');
    setMessageSent(true);
  };
  const handleSubmit = event => {
    event.preventDefault();
    sendTelegramMessage(
      `${contact} said: 
${message}`, true
    )
    clearForm();
  };
  return (
    <section className="colorlib-contact" data-section="contact">
      <div className="colorlib-narrow-content">
        <div className="row">
          <SectionHeader title="Inquiries?" subTitle="CONTACT" />
        </div>
        <div className="row">
          <div className="col-md-5">
            <div
              className="colorlib-feature colorlib-feature-sm animate-box"
              data-animate-effect="fadeInLeft">
              <div className="colorlib-icon">
                <i className="icon-globe-outline" />
              </div>
              <div className="colorlib-text">
                <p>
                  <a href="mailto:contact@javiersanchez.dev">
                    contact@javiersanchez.dev
                  </a>
                </p>
              </div>
            </div>

            <div
              className="colorlib-feature colorlib-feature-sm animate-box"
              data-animate-effect="fadeInLeft">
              <div className="colorlib-icon">
                <i className="icon-map" />
              </div>
              <div className="colorlib-text">
                <p>Saltillo, México.</p>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-md-push-1">
            <div className="row">
              <div
                className="col-md-10 col-md-offset-1 col-md-pull-1 animate-box"
                data-animate-effect="fadeInRight">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      onChange={e => setContact(e.target.value)}
                      value={contact}
                      placeholder="Contact info"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="7"
                      value={message}
                      className="form-control"
                      onChange={e => setMessage(e.target.value)}
                      placeholder="Message"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="submit"
                      className="btn btn-primary btn-send-message"
                      disabled={messageSent}
                      value={messageSent ? 'Message sent' : 'Send Message'}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
