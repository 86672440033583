export const BlogEntry = ({ date, minutesToRead, title, description, picture, pictureDescription, entryLink }) => {
  return (
    <div>
      <div className="col-md-4 col-sm-6 animate-box" data-animate-effect="fadeInLeft">
        <div className="blog-entry">
          <a href={entryLink} target="_blank" rel="noopener noreferrer" className="blog-img">
            <img
              src={picture}
              style={{ width: '100%', height: '250px', objectFit: 'cover' }}
              className="img-responsive"
              alt={pictureDescription}
            />
          </a>
          <div className="desc">
            <span>
              <small>{date} </small> | <small> {`${minutesToRead} minutes`} </small>
            </span>
            <h3>
              <a href={entryLink} target="_blank" rel="noopener noreferrer">
                {title}
              </a>
            </h3>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogEntry;
