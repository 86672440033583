export const ListItem = ({
  title,
  subtitle,
  icon,
  link,
  buttonTitle,
  image,
  style,
}) => {
  return (
    <li style={{ backgroundImage: image }}>
      <div className="overlay" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
            <div className="slider-text-inner">
              <div className="desc">
                <h1 style={{ color: 'white' }}>
                  {title}
                  <br />
                  {subtitle}
                </h1>
                <p>
                  <a
                    className="btn btn-primary btn-learn"
                    style={style}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer">
                    {buttonTitle}
                    <i className={'icon-' + icon} />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};
